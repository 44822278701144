import getConfig from 'next/config';
import { useRouter } from "next/router";
import { SWRConfig } from 'swr';
import '../styles/global.scss';
import '@popperjs/core';
import { UserProvider } from '../components/userContext';
import '../styles/nprogress.css';
import { fetcher } from '../components/utils';
import { useEffect, useState } from 'react';
import NProgress from 'nprogress';
import Router from 'next/router';
import TagManager from 'react-gtm-module';
import { SessionProvider, useSession, signIn } from "next-auth/react";

Router.events.on('routeChangeStart', (url) => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// Client-side / public config
const clientSideConfig = getConfig();

export default function App({ Component, pageProps }) {
  const session = pageProps ? pageProps.session : null;
  const isUnAuthenticatedPage = pageProps
    ? pageProps.isUnAuthenticatedPage
    : false;

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-M7XM3G5' });
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <SessionProvider session={session}>
      <SWRConfig
        value={{
          fetcher: fetcher,
          dedupingInterval: clientSideConfig.publicRuntimeConfig.swrDedupingInterval,
          refreshInterval: clientSideConfig.publicRuntimeConfig.swrRefreshInterval,
          revalidateOnFocus: clientSideConfig.publicRuntimeConfig.swrRevalidateOnFocus,
        }}>
        <UserProvider>
          {!isUnAuthenticatedPage ? (
            <Layout
              roleNeededForPageAccess={
                pageProps ? pageProps.roleNeededForPageAccess : null
              }
            >
              <Component {...pageProps} />
            </Layout>
          ) : (
            <Component {...pageProps} />
          )}
        </UserProvider>
      </SWRConfig>
    </SessionProvider>
  );
}

function Layout({ children }) {
  const nextConfig = getConfig();
  const { data: session, status: loginStatus } = useSession();
  const router = useRouter();
  const [userProfileFetchSuccess, setUserProfileFetchSuccess] = useState(false);
  const providerId = nextConfig.publicRuntimeConfig.nextAuthKeycloakProviderId;
  useEffect(() => {
    if (loginStatus === 'unauthenticated') {
      signIn(providerId, {
        callbackUrl: router.asPath,
      });
    } else if (loginStatus === 'authenticated') {
      if (
        session &&
        session.user &&
        session.user.profile &&
        !session.user.profile.error
      ) {
        setUserProfileFetchSuccess(true);
      } else {
        console.log('loginStatus:', loginStatus);
        router.push('/500');
      }
    }
  }, [session]);
  return (<>
    {loginStatus === 'loading' && <></>}
    {loginStatus === 'unauthenticated' && (
      <>
        <p className="text-center">Redirecting...</p>
      </>
    )}
    {loginStatus === 'authenticated' && userProfileFetchSuccess && (
      <>
       { children } 
      </>
      
    )}
  </>
  );
}

function getInitialProps() {
  return {};
}
App.getInitialProps = getInitialProps;