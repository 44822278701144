import { createContext, useContext, useState, useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { readCurrentLoggedInUserPermissions } from "./permissionsApi";

const UserContext = createContext();
const UserProvider = ({ children }) => {
    const { data: session } = useSession();
    const [user, setUser] = useState((session && session.user) ? session.user : { rolesAndGroups: {}, roles: {}, isAllowed: false });

    useEffect(() => {
        if (session && session.user) {
            const fetchPermissions = async () => {
                try {
                    let userWithPermissions = {
                        isLoggedIn: true,
                        email: session.user.email,
                        name: session.user.name,
                        roles: []
                    }

                    const data = await readCurrentLoggedInUserPermissions(session.user.email);
                    if (data) {
                        setUser({...userWithPermissions, ...data});
                    }
                } catch (error) {
                    console.log(error)
                }
            };
            fetchPermissions();
        } else {
            setUser({
                isLoggedIn: false,
            });
        }

    }, [session]);
    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
}

const useUserContext = () => {
    return useContext(UserContext);
}


export { UserProvider, useUserContext };
