import getConfig from 'next/config';
import fetch from 'unfetch';

const clientSideConfig = getConfig();

export async function readCurrentLoggedInUserPermissions(email) {
    const url =  `${clientSideConfig.publicRuntimeConfig.apiProxyBasePath}/radius/roles?email=${email}`

    let userWithPermissions = {roles: []}
    try {
        let apiResponse = await fetch(url);
        if (apiResponse.ok) {
            const data = await apiResponse.json();
            userWithPermissions.roles = (data && data.length > 0) ?  data : [];
        } else {
            console.log("Read Roles and Permissions returned: ", apiResponse.status);
            userWithPermissions.roles = [];
        }
    } catch (error) {
        console.log("Error when reading user permissions:", error);
        userWithPermissions.roles = [];
    }
    const userRolePermissions = userPermissionLevelCalcBasedOnRoleLabel(userWithPermissions);
    return {isLoggedIn: true, ...userRolePermissions};
}

const userPermissionLevelCalcBasedOnRoleLabel = (user) => {
    let mccAdmin = false;
    let mccQualityReview = false;
    let mccTechnicalContentManager = false;
    let mccIcpsrPermitted = false;
    let mccIcpsrArchiveManager = false;
    if (user.roles && user.roles.length > 0) {
        mccAdmin = user.roles.some(function (role) {
            return role.roleLabel === 'mcc-admins';
        });
        mccQualityReview = user.roles.some(function (role) {
            return role.roleLabel === 'mcc-quality-review';
        });
        mccTechnicalContentManager = user.roles.some(function (role) {
            return role.roleLabel === 'mcc-technical-content-managers';
        });
        mccIcpsrPermitted = user.roles.some(function (role) {
            return role.roleLabel === 'mcc-icpsr-permitted';
        });
        mccIcpsrArchiveManager = user.roles.some(function (role) {
            return role.roleLabel === 'mcc-icpsr-archive-manager';
        });
    }
    return {
        isMccAdmin: mccAdmin,
        isMccQualityReview: mccQualityReview,
        isMccTechnicalContentManager: mccTechnicalContentManager,
        isMccIcpsrPermitted: mccIcpsrPermitted,
        isMccIcpsrArchiveManager: mccIcpsrArchiveManager,
    };
}