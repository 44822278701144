import getConfig from 'next/config';
import React from 'react';
import {useSession} from 'next-auth/react';
import fetch from 'unfetch';
// Client-side utilities

// Fetcher function for data fetching / updating with options
const fetcher = async (...args) => {
    try {
        const response = await fetch(...args);
        // if the server replies, there's always some data in json
        // if there's a network error, it will get thrown at the previous line
        if (response.ok) {
            const data = await response.json();
            return data;
        }
        const error = await response.json();
        const ex = new Error(error.message);
        ex.response = error.error;
        throw ex;
    } catch (error) {
        console.error(`api call failed - ${error}`);
        return null;
    }
};

// // Display page content only if user is logged in
// const withUserAuthNCheck = (pageContent) => {

//     const { data: session } = useSession();
//     const nextConfig = getConfig();
//     if(!session || !session.user) {
//         signIn(nextConfig.publicRuntimeConfig.nextAuthKeycloakProviderId, { callbackUrl: router.asPath })
//     } 
//     return ((session && session.user)  
//         ? ( pageContent )
//         : ( <>Loading...</> ))
// };

const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const flattenElements = (schemaVersion) => {
    let elements = [];
    if (schemaVersion.elements[0].rdfName == 'ldp_Container') {
        for (let container of schemaVersion.elements) {
            elements = elements.concat([{ rdfName: 'ldp_Container', label: container.label, propertiesMap: { uiControl: 'sectionTitle' } }]);
            elements = elements.concat(container.elements);
        }
    } else elements = schemaVersion.elements;
    return elements;
};

const generateServerErrorMessage = (errorCode) => {
    switch (errorCode) {
        case 503:
        case 500:
            return 'Server is down. Please try again later';
        default:
            break;
    }
};
const showModal = async (modalRef) => {
    const modalEle = modalRef.current;
    const Modal = (await import('bootstrap/js/dist/modal.js')).default;
    const bsModal = new Modal(modalEle, {
        backdrop: 'static',
        keyboard: false,
    });
    bsModal.show();
};

const hideModal = async (modalRef) => {
    const modalEle = modalRef.current;
    const Modal = (await import('bootstrap/js/dist/modal.js')).default;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
};
export { fetcher, formatBytes, flattenElements, generateServerErrorMessage, showModal, hideModal };
